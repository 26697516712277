.formContainer {
  position: absolute;
  right: 0;
  overflow: hidden;
  height: 0;
  opacity: 0;
  z-index: 5;
  border-radius: 40px;
}

.displayFormContainer {
  height: auto;
  opacity: 1;
}

.formWrapper {
  background-color: #f0b323;
  width: 325px;
  position: relative;
  font-family: 'Roboto';
  padding: 35px;
  z-index: 2;
}

.exitIconWrapper {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.exitIconWrapper:hover {
  filter: brightness(.8);
}

.exitIconWrapper svg {
  font-size: 40px;
  color: white;
}

.title {
  font-size: 34px;
  text-align: center;
  margin-bottom: 20px;
}

.inputWrapper input,
.inputWrapper textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid;
  margin-bottom: 15px;
  margin-top: 2px;
  border: 2px solid transparent;
}

.inputWrapper input {
  height: 25px;
}

.inputWrapper input:focus,
.inputWrapper textarea:focus {
  outline: none;
  border: 2px solid #0096ff;
}

.inputWrapper textarea {
  height: 50px;
}

.radioGroup {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.radioGroup .inputWrapper input {
  cursor: pointer;
}

.buttonWrapper {
  text-align: center;
  color: #333;
}

.buttonWrapper input {
  border: 2px solid white;
  background-color: transparent;
  padding: 15px 40px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: bolder;
  margin-top: 20px;
  cursor: pointer;
  color: #333;
}

.buttonWrapper input:hover {
  background-color: white;
}

@media screen and (max-width: 768px) {
  .formContainer {
    position: relative;
    left: 0;
  }

  .formWrapper {
    width: initial;
  }
}