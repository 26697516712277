.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  border-bottom: 1px solid lightgray;
}

.headerWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.churchIconWrapper svg {
  font-size: 24px;
}

.title {
  font-size: 28px;
  margin-left: 17px;
  font-weight: 400;
}