.container {
  position: absolute;
  right: 12px;
  top: 25px;
}

.plusIconWrapper {
  background-color: white;
  padding: 10px 12px;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
}

.plusIconWrapper:hover {
  filter: brightness(.8);
}

.plusIconWrapper svg {
  font-size: 30px;
}

@media screen and (max-width: 788px) {
  .container {
    top: 155px
  }
}