.mapContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 15px;
  font-weight: bold;
  border-radius: 8px;
  font-family: 'Roboto';
}

.sidebarStyle div:not(:last-child) {
  margin-bottom: 5px;
}

