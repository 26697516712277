.container {
  position: absolute;
  left: 0;
  top: 0;
  margin: 15px;
  z-index: 2;
}

.inputWrapper {
  height: 45px;
  width: 350px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 59%);
  display: flex;
}

.inputWrapper input {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  text-indent: 10px;
  font-size: 16px;
}

.searchIconWrapper {
  display: flex;
  align-items: center;
  height: inherit;
  padding: 0 15px;
  cursor: pointer;
}

.searchIconWrapper:hover {
  background-color: lightgray;
  border-radius: 5px;
  transform: scale(.9);
}

.vertical {
  width: 2px;
  height: 25px;
  background: lightgray;
  margin-top: 9px;
}

.m_container {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 3;
}

.m_churchSuggestionContainer {
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  z-index: 50;
}

.m_displayFullList_churchSuggestionContainer {
  top: 285px;
  z-index: 1;
  overflow-y: scroll;
  z-index: 3;
}

.m_buttonsGroupContainer {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Roboto';
}

.m_fullList_buttonsGroupContainer {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 1;
  height: 15px;
}

.m_buttonsGroupContainer div span {
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
}

.m_angleIconWrapper {
  background: #f0f0f0;
  width: 100px;
  text-align: center;
  position: relative;
  top: 1px;
  border: 1px solid;
  border-radius: 8px;
} 

.m_angleIconWrapper svg {
  font-size: 22px;
}

.m_listWrapper {
  position: absolute;
  top: 20px;
  width: 100%;
}

.m_infoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 50px;
  font-family: 'Roboto';
}

.m_firstColWidth {
  width: 210px;
  padding-right: 20px;
}

.m_secColWidth div:last-child {
  font-size: 13px;
}

.m_smallestText {
  font-size: 12px;
}

.m_largeText {
  font-size: 20px;
}

.m_distanceText {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-top: 7px;
}

.m_distanceText span {
  font-size: 14px;
  margin-left: 10px;
}

.m_seeOnMapText {
  padding-bottom: 10px;
  text-align: center;
  font-family: 'Roboto';
  color: #0096ff;
}

.m_borderBtm {
  border-bottom: 1px solid lightgray;
}

.m_directionIconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  cursor: pointer;
}

.m_directionIconWrapper {
  filter: brightness(1.3)
}

.m_directionIconWrapper svg {
  font-size: 24px;
  background: #1a73e8;
  border-radius: 50%;
  color: white;
  padding: 14px 0;
}

.m_directionIconWrapper div {
  margin-top: 4px;
  color: #1a73e8;
  font-size: 12px;
}

.suggestionsContainer,
.churchSuggestionsContainer {
  background-color: white;
  margin-top: 2px;
  width: 350px;
}

.scroll {
  height: 350px;
  overflow-y: scroll;
}

.churchSuggestionsContainer::-webkit-scrollbar {
  width: 1em;
}
 
.churchSuggestionsContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.churchSuggestionsContainer::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.rowWrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
  border-radius: 4px;
  font-family: 'Roboto';
  cursor: pointer;
}

.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
}

.infoWrapper:hover {
  background-color: #e2e2e2;
}

.infoWrapper div:first-child {
  width: 185px;
}

.ministryTypeText {
  font-size: 14px;
}

.churchNameText {
  font-weight: bold;
  margin-bottom: 1px;
  color: #1a73e8;
  text-decoration: underline;
  margin-bottom: 3px;
}

.distanceWrapper {
  width: 90px;
}

.distanceWrapper div:first-child {
  font-size: 20px;
  width: inherit;
}

.deafIcon,
.hearingIcon {
  padding: 4px 10px;
  border-radius: 10px;
  width: 75px;
  text-align: center;
}

.deafIcon {
  background-color: #f0b323;
}

.hearingIcon {
  background-color: #cccccc;
}

.seeOnMapText {
  text-align: center;
  display: none;
  color: #1a73e8;
  width: 110px;
  border-radius: 4px;
  padding: 5px 0;
  margin: 10px auto;
}

.seeOnMapText:hover {
  text-decoration: underline;
}

.loaderWrapper {
  background-color: white;
  padding: 25px 0;
  display: flex;
  justify-content: center;
  border: 1px solid rgb(200, 200, 200);
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 768px) {

  .container {
    position: initial;
    left: initial;
    top: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .suggestionsContainer {
    z-index: 1;
  }

  .churchSuggestionsContainer {
    position: fixed;
    left: 0;
    bottom: 0;
    height: initial;
    width: 100%;
    display: flex;
    overflow-x: scroll;
  }

  .churchSuggestionsContainer::-webkit-scrollbar {
    width: initial;
  }
   
  .churchSuggestionsContainer::-webkit-scrollbar-track {
    box-shadow: initial;
  }
   
  .churchSuggestionsContainer::-webkit-scrollbar-thumb {
    background-color: initial;
    outline: initial;
  }

  .rowWrapper {
    border-bottom: none;
    border-right: 1px solid lightgray;
    align-items: initial;
    flex-direction: column;
  }

  .infoWrapper {
    flex-direction: column;
    height: 100%;
    width: inherit;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .seeOnMapText {
    display: inline-block;
  }

  .distanceWrapper {
    margin-top: 25px;
  }

  .loaderWrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 399px) {
  .inputWrapper,
  .suggestionsContainer {
    width: 100%;
    z-index: 2;
  }
}

@media screen and (max-width: 349px) {
  .m_distanceText {
    font-size: 14px;
  }
  .m_distanceText span {
    font-size: 12px;
  } 
  .m_firstColWidth {
    width: 170px;
  }

  .m_smallText {
    font-size: 14px;
  }

  .m_smallerText {
    font-size: 14px;
  }

  .m_smallestText {
    font-size: 12px;
  }
}