.container {
  position: absolute;
  top: 25px;
  left: 660px;
}

.userLocationIconWrapper {
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
}

.userLocationIconWrapper:hover {
  filter: brightness(.8);
}

.userLocationIconWrapper svg {
  font-size: 30px;
}

@media screen and (max-width: 788px) {
  .container {
    left: initial;
    right: 12px;
    top: 220px;
  }
}