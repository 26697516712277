.container {
  position: absolute;
  top: 0;
  left: 390px;
  margin: 15px 0;
  z-index: 2;
}

.wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 245px;
  height: 47px;
  font-family: 'Roboto';
  background: white;
  padding: 10px 0;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 59%);
}

.btnWrapper {
  width: 100px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #a5a5a5;
  transform: scale(1);
  transition: all .4s;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 59%);
}

.btnWrapper:hover {
  filter: brightness(1.1);
}

.btnWrapper div {
  padding: 10px 0;
  cursor: pointer;
  color: #333;
}

.wrapper .btnWrapper:first-child {
  background-color: #f0b323;
}

.wrapper .btnWrapper:last-child {
  background-color: #cccccc;
}

.unselected {
  transform: scale(.95);
  transition: all .4s;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .container {
    position: relative;
    left: initial;
    top: -10px;
    width: 350px;
    margin: 0 auto;
  }
  .wrapper {
    width: initial;
    box-shadow: none;
    background-color: initial;
  }
}

@media screen and (max-width: 399px) {
  .container {
    width: 100%;
  }
}
