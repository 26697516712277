
.infoWrapper {
  padding: 15px;
  border-bottom: 1px solid lightgray;
}

.churchNameText {
  font-weight: bolder;
  font-size: 24px;
  padding-right: 45px;
  margin-bottom: 3px;
}

.address {
  margin-right: 7px;
}

.addressWrapper {
  margin-bottom: 10px;
  margin-right: 45px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.copyIconWrapper {
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.copyIconWrapper:hover {
  background-color: #e1e1e1;
}

.copyIconWrapper svg {
  color: #333;
}

.copyIconWrapper .tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -35px;
  left: -40px;
}

.copyIconWrapper:hover .tooltipText {
  visibility: visible;
}

.copyIconWrapper svg {
  font-size: 20px;
}

.ministryTypeText {
  font-weight: 100;
  font-size: 20px;
}

.extraBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.typeText,
.liveText {
  padding: 4px 10px;
  border-radius: 10px;
  font-weight: bolder;
  text-transform: uppercase;
  color: #333;
}

.liveText {
  border: 1px solid #333;
}

.liveText a {
  text-decoration: none;
  color: #333;
}

.liveText:hover {
  transform: scale(.93);
}

.seeOnMapText {
  margin-top: 15px;
  color: #1a73e8;
  cursor: pointer;
}

.seeOnMapText:hover {
  text-decoration: underline;
}