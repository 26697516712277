.container {
  width: 385px;
  background: white;
  height: 100%;
  position: absolute;
  font-family: 'Roboto';
  overflow-y: scroll;
  z-index: 3;
}

/* width */
.container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.container::-webkit-scrollbar-thumb {
  background: #333; 
  border-radius: 10px;
}

.exitIconWrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.exitIconWrapper:hover svg {
  color: black;
  background-color: white;
  border-radius: 50%;
}

.exitIconWrapper svg {
  font-size: 40px;
  color: white
}

.bgColor svg {
  color: black;
}

.bgColor:hover svg {
  color: white;
  background-color: black;
  border: 1px solid black;
}

@media screen and (max-width: 768px) {

  @keyframes pulse {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .container {
    width: 100%;
    top: 0;
    /* animation: pulse 1s; */
    z-index: 3;
  }

}