.rowWrapper {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  border-radius: 4px;
  font-family: 'Roboto';
  cursor: pointer;
}

.rowWrapper:hover {
  background-color: #e2e2e2;
}