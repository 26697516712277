.container {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 100px;
}

.wrapper {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  padding: 0 15px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 59%);
}

.searchIconWrapper svg {
  font-size: 14px;
  color: #1a73e8;
}

.text {
  font-family: 'Roboto';
  padding: 8px 0px;
  margin-left: 10px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .container {
    top: 135px;
  }
}