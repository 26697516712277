.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  border-bottom: 1px solid lightgray;
}

.headerWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.churchIconWrapper svg {
  font-size: 24px;
}

.title {
  font-size: 28px;
  margin-left: 17px;
  font-weight: 400;
}

.wrapper {
  width: 100%;
}

.iconWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 40px;
  margin-bottom: 10px;
}

.iconWrapper div:first-child {
  margin-right: 15px;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}

.iconWrapper div:first-child .tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -50px;
  left: 50px;
}

.iconWrapper div:first-child:hover {
  background-color: #e1e1e1;
}

.iconWrapper div:first-child:hover .tooltipText {
  visibility: visible;
}

.iconWrapper div svg {
  font-size: 26px;
  color: #333;
}

.iconWrapper div:last-child {
  overflow-wrap: anywhere;
  color: #1a73e8;
  font-weight: bold;
  cursor: pointer;
}

.iconWrapper div:last-child:hover {
  text-decoration: underline;
}

@media screen and (max-width: 399px) {
  .iconWrapper {
    padding: 0;
  }
  .iconWrapper div:first-child {
    margin-right: 5px;
  }
}