
.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid lightgray;
}

.topButtonsWrapper,
.bottomButtonsWrapper {
  display: flex;
}

.bottomButtonsWrapper {
  margin-top: 15px;
}

.directionIconWrapper,
.websiteIconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  cursor: pointer;
}

.mgRt {
  margin-right: 30px;
}

.directionIconWrapper:hover,
.websiteIconWrapper:hover {
  filter: brightness(1.3);
}

.directionIconWrapper svg,
.websiteIconWrapper svg {
  font-size: 24px;
  background: #1a73e8;
  border-radius: 50%;
  color: white;
}

.directionIconWrapper svg {
  padding: 14px 0;
}

.websiteIconWrapper svg {
  padding: 14px;
}

.directionIconWrapper div,
.websiteIconWrapper div {
  margin-top: 4px;
  color: #1a73e8;
  font-size: 12px;
}

.brandIconWrapper {
  cursor: pointer;
  margin-right: 30px;
  padding: 5px;
  border-radius: 35%;
}

.brandIconWrapper:hover {
  box-shadow: 4px 4px 7px 0px rgb(0 0 0 / 75%);
}

.brandIconWrapper svg {
  font-size: 50px;
}

@media screen and (max-width: 449px) {
  .topButtonsWrapper,
  .bottomButtonsWrapper {
    width: 100%;
    justify-content: space-evenly;
  }
  
  .directionIconWrapper,
  .brandIconWrapper {
    margin-right: 0;
  }
}
