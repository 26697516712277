.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  border-bottom: 1px solid lightgray;
}

.headerWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.churchIconWrapper svg {
  font-size: 24px;
}

.title {
  font-size: 28px;
  margin-left: 17px;
  font-weight: 400;
}

.wrapper {
  width: 100%;
}

.servicesWrapper {
  margin: 15px 15px 40px 15px;
}

.subtitle {
  font-size: 24px;
  margin-right: 10px;
}

.subtitleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.iconWrapper svg {
  font-size: 24px;
}

.serviceDetailWrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid #aaaaaa;
  padding: 5px;
  border-radius: 6px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.serviceTypeText {
  width: 140px;
}

.dayOfWeekText {
  width: 90px;
}

.startTimeText {
  width: 68px;
  text-align: right;
}

.note {
  margin-top: 10px;
}

@media screen and (max-width: 380px) {
  .serviceDetailWrapper {
    font-size: 14px;
  }
}

@media screen and (max-width: 345px) {
  .servicesWrapper {
    margin-left: 0;
    margin-right: 0;
  }
}